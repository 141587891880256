import React, { useEffect, useState, useRef } from "react";
import InputField from "../../InputField/InputField";
import Strings from "../../../utils/Strings";
import SelectField from "../../SelectField/SelectField";
import configuration from "../../../Config/configuration";
import Spinner from "../../Spinner/Spinner";
import {
  bodyTypeOptions,
  complexionOptions,
  countryOptions,
  genderOption,
  languagesOptions,
  maritalOption,
  spiritualMasterOptions,
  spiritualStatusOptions,
  wantChildrenOption,
} from "../../../utils/data";
import { authorizedPost } from "../../../Config/networkWithToken";
import {
  config,
  getValueFromLocalStorage,
  localStorageKey,
  updateValueInLocalStorage,
} from "../../../utils/helper";

const BasicInformation = ({
  currentStep,
  steps,
  setCurrentStep,
  userDetails,
  userRole,
  setUserDetails,
}) => {
  const prevFormData = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    initiatedName: "",
    dob: "",
    gender: "",
    birth: false,
    ethnicOrigin: "",
    motherTongue: "",
    languagesCanSpeak: [],
    languagesCanWrite: [],
    spiritualStatus: "",
    spiritualMaster: "",
    height: "",
    weight: "",
    bodyType: "",
    complexion: "",
    currentPlace: "",
    city: "",
    state: "",
    country: "",
    nationality: "",
    describeYourself: "",
    activities: "",
    devotionalService: "",
    wantChildren: "",
    medicalHistory: "",
    connectedTemple: "",
    childrenCount: 0,
    regulativePrinciples: false,
    fourRegulativePrinciples: [],
  });
  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 21,
    today.getMonth(),
    today.getDate(),
  )
    .toISOString()
    .split("T")[0];

  const [userData, setUserData] = useState({
    countOfMantra: "",
    maritalStatus: "",
    name: "",
    dob: "",
    gender: "",
    age : 21
  });

  const [errors, setErrors] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    if (userRole === "admin") {
      console.log(userDetails);
      if (userDetails) {
        fetchBasicInformation(userDetails);
      }
    } else {
      const userData = JSON.parse(localStorage.getItem("userData"));
      fetchBasicInformation(userData);
    }
  }, [userDetails]);

  const fetchBasicInformation = (data) => {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    const userData = data;

    if (userData) {
      const fetchedData = {
        name: userData.name || "",
        gender: userData.gender || "",
        initiatedName: userData?.profile?.basicInformation?.initiatedName || "",
        dob: new Date(userData?.dob).toLocaleDateString("en-CA") || "",
        birth: userData?.profile?.basicInformation?.birth || false,
        ethnicOrigin: userData?.profile?.basicInformation?.ethnicOrigin || "",
        motherTongue: userData?.profile?.basicInformation?.motherTongue || "",
        languagesCanSpeak:
          userData?.profile?.basicInformation?.languagesCanSpeak || [],
        languagesCanWrite:
          userData?.profile?.basicInformation?.languagesCanWrite || [],
        spiritualStatus:
          userData?.profile?.basicInformation?.spiritualStatus || "",
        spiritualMaster:
          userData?.profile?.basicInformation?.spiritualMaster || "",
        height: userData?.profile?.basicInformation?.height || "",
        weight: userData?.profile?.basicInformation?.weight || "",
        bodyType: userData?.profile?.basicInformation?.bodyType || "",
        complexion: userData?.profile?.basicInformation?.complexion || "",
        currentPlace: userData?.profile?.basicInformation?.currentPlace || "",
        city: userData?.profile?.basicInformation?.city || "",
        state: userData?.profile?.basicInformation?.state || "",
        country: userData?.profile?.basicInformation?.country || "",
        nationality: userData?.profile?.basicInformation?.nationality || "",
        describeYourself:
          userData?.profile?.basicInformation?.describeYourself || "",
        activities: userData?.profile?.basicInformation?.activities || "",
        devotionalService:
          userData?.profile?.basicInformation?.devotionalService || "",
        wantChildren: userData?.profile?.basicInformation?.wantChildren || "",
        medicalHistory:
          userData?.profile?.basicInformation?.medicalHistory || "",
        connectedTemple:
          userData?.profile?.basicInformation?.connectedTemple || "",
        regulativePrinciples:
          userData?.profile?.basicInformation?.regulativePrinciples || false,
        childrenCount: userData?.profile?.basicInformation?.childrenCount || 0,
        fourRegulativePrinciples:
          userData?.profile?.basicInformation?.fourRegulativePrinciples || [],
      };

      setUserData({
        countOfMantra: userData?.countOfMantra || "",
        maritalStatus: userData?.maritalStatus || "",
        name: userData?.name || "",
        dob: new Date(userData?.dob).toLocaleDateString("en-CA") || "",
        gender: userData?.gender || "",
      });

      setFormData(fetchedData);
      prevFormData.current = fetchedData;
    }
    console.log(userData);
  };

  const validate = (values) => {
    const errors = {};
    const requiredFields = [
      "initiatedName",
      "ethnicOrigin",
      "motherTongue",
      "spiritualStatus",
      "height",
      "weight",
      "bodyType",
      "complexion",
      "currentPlace",
      "nationality",
      "describeYourself",
      "medicalHistory",
      "birth",
      "connectedTemple",
      "spiritualMaster",
      "languagesCanSpeak",
      "state",
      "wantChildren",
    ];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        console.error(`Validation failed for: ${field}`);
        errors[field] = "Required";
      } else if (values?.[field]?.length === 0) {
        console.error(`Validation failed for: ${field}`);
        errors[field] = "Required";
      }
    });
    return errors;
  };

  const userDataValidate = (values) => {
    const errors = {};
    const requiredFields = [
      "countOfMantra",
      "maritalStatus",
      "name",
      "dob",
      "gender",
    ];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        console.error(`Validation failed for: ${field}`);
        errors[field] = "Required";
      }
    });
    return errors;
  };
  const fourRegulativePrinciplesOptions = [
    "No meat eating",
    "No gambling",
    "No intoxication",
    "No illicit sex",
  ];

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target ? e.target : e;

    if (
      name === "countOfMantra" ||
      name === "maritalStatus" ||
      name === "gender" ||
      name === "name" ||
      name === "dob"
    ) {
      if(name === "dob" ){
        const birthDate = new Date(value);
        console.log("DOB :",birthDate);
        
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
      
        if (age < 21) {
          errors.dob = "You must be at least 21 years old";
        }
      
        setUserData((prevUserData) => ({
          ...prevUserData,
          ["age"]: age,
        }));
        updateValueInLocalStorage("userData", "age", age);
      }
      
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: type === "checkbox" ? checked : value,
      }));
    } else if (name === "fourRegulativePrinciples") {
      setFormData((prevData) => ({
        ...prevData,
        fourRegulativePrinciples: checked
          ? [...(prevData?.fourRegulativePrinciples || []), value]
          : (prevData?.fourRegulativePrinciples || []).filter(
              (item) => item !== value,
            ),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = Object.assign(
      {},
      validate(formData),
      userDataValidate(userData),
    );

    if (Object.keys(newErrors).length === 0) {
      setIsButtonLoading(true);

      if (
        JSON.stringify(prevFormData.current) === JSON.stringify(formData) &&
        JSON.stringify(userData) ===
          JSON.stringify(prevFormData.current.userData)
      ) {
        handleNext("next");
        return;
      }

      const data = {
        basicInformation: { ...formData },
        userData: {
          ...userData,
          // gender: userData.gender,
        },
      };

      console.log("data", data);

      try {
        let res = [];
        if (userRole === "admin") {
          res = await authorizedPost(
            `${configuration.apis.saveProfile}?editUserId=${userDetails?._id}`,
            data,
            config,
          );
        } else {
          res = await authorizedPost(
            configuration.apis.saveProfile,
            data,
            config,
          );
        }

        if (res.data.status) {
          handleNext("next");

          const updatedProfileData = res?.data?.data;
          const profileCompletionPercentage =
            res?.data?.profileCompletionPercentage;
          console.log(updatedProfileData);

          if (userRole === "admin") {
            if (updatedProfileData) {
              setUserDetails({
                ...userDetails,
                profile: {
                  ...userDetails.profile,
                  basicInformation: {
                    ...updatedProfileData?.profile?.basicInformation,
                  },
                },
                countOfMantra: userData?.countOfMantra,
                maritalStatus: userData?.maritalStatus,
                name: userData?.name,
                gender: userData?.gender,
                dob: userData?.dob,
              });
            }
          } else {
            if (updatedProfileData) {
              Object.keys(updatedProfileData).forEach((field) => {
                updateValueInLocalStorage(
                  "userData",
                  field,
                  updatedProfileData[field],
                );
              });
            }
            if (profileCompletionPercentage) {
              updateValueInLocalStorage(
                "userData",
                "profileCompletionPercentage",
                profileCompletionPercentage,
              );
            }

            updateValueInLocalStorage(
              "userData",
              "countOfMantra",
              userData.countOfMantra,
            );
            updateValueInLocalStorage(
              "userData",
              "maritalStatus",
              userData.maritalStatus,
            );
            updateValueInLocalStorage("userData", "name", userData.name);
            updateValueInLocalStorage("userData", "gender", userData.gender);
            updateValueInLocalStorage("userData", "dob", userData.dob);
          }
        }
      } catch (err) {
        console.error("error", err);
      } finally {
        setIsButtonLoading(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  // Handle navigation between steps
  const handleNext = (direction) => {
    if (direction === "next" && currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else if (direction === "prev" && currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Required label component
  const RequiredLabel = ({ label }) => (
    <span>
      {label}
      <span className="text-red-400">*</span>
    </span>
  );

  return (
    <div className="flex min-h-screen w-full items-center justify-center">
      <div className="mx-auto mt-10 flex w-[95%] flex-col gap-1 rounded-lg bg-bgWhite pb-3">
        <form
          onSubmit={handleSubmit}
          className="mt-4 flex w-full flex-col gap-3 px-2 lg:gap-1 lg:p-1"
        >
          <div>
            <div className="mx-auto mt-5 flex w-[95%] flex-col gap-5 pb-3">
              <div className="flex flex-col gap-3">
                <h3 className="font-bold text-textGreen">
                  {Strings.BASIC_INFO}
                </h3>
                <h1 className="pb-3 text-3xl">{Strings.BASIC_PROFILE}</h1>
                <hr />
              </div>
              <div className="pb-2">
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    className="w-full md:w-[50%]"
                    fieldType="field1"
                    label="Name"
                    name="name"
                    type="text"
                    // disabled={true}
                    placeholder="Enter name"
                    value={userData.name}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[A-Za-z\s]*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    error={errors.name}
                  />
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label={
                      <RequiredLabel
                        label={
                          <>
                            Initiated Name
                            <span className=" text-xs text-textBlack text-opacity-70">
                              (Note : A name with significance.)
                            </span>
                          </>
                        }
                      />
                    }
                    name="initiatedName"
                    type="text"
                    placeholder="Enter Initiated name"
                    value={formData.initiatedName}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[A-Za-z\s]*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    error={errors.initiatedName}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <InputField
                    fieldType="field1"
                    className="w-full md:w-[50%]"
                    label="Date of Birth"
                    name="dob"
                    type="Date"
                    // disabled={true}
                    placeholder="Enter dob"
                    value={userData.dob}
                    onChange={handleChange}
                    error={errors.dob}
                    max={maxDate}
                  />
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Gender" />}
                    name="gender"
                    placeholder="Select Gender"
                    options={genderOption}
                    value={userData.gender}
                    onChange={handleChange}
                    error={errors.genderOption}
                  />
                </div>
                <div className="w-full md:w-[50%]">
                  <div className="mb-3 mt-4 flex items-center gap-2">
                    <input
                      className="mb-1 h-[16px] w-[16px] cursor-pointer focus:outline-none"
                      type="checkbox"
                      name="birth"
                      checked={formData.birth}
                      onChange={handleChange}
                    />
                    <label
                      className="font-helvetica whitespace-nowrap text-xs font-semibold text-textBlack md:text-sm"
                      htmlFor="birth"
                    >
                      Is actual date of birth
                      {<RequiredLabel label />}
                    </label>
                  </div>
                  {errors.birth && (
                    <span className="text-xs text-textRed">{errors.birth}</span>
                  )}
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Ethnic Origin" />}
                    name="ethnicOrigin"
                    placeholder="Select Ethnic Origin"
                    options={countryOptions}
                    value={formData.ethnicOrigin}
                    onChange={handleChange}
                    error={errors.ethnicOrigin}
                  />
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Mother Tongue" />}
                    name="motherTongue"
                    placeholder="Select Mother Tongue"
                    options={languagesOptions}
                    value={formData.motherTongue}
                    onChange={handleChange}
                    error={errors.motherTongue}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Languages you can speak" />}
                    name="languagesCanSpeak"
                    placeholder="Select Languages"
                    options={languagesOptions}
                    value={formData.languagesCanSpeak}
                    onChange={handleChange}
                    isMulti={true}
                  />
                  <InputField
                    className="w-full md:w-[50%]"
                    fieldType="field1"
                    label={<RequiredLabel label="No.of Rounds of Chanting" />}
                    name="countOfMantra"
                    type="text"
                    placeholder="Enter Count Of Mantra"
                    value={userData.countOfMantra}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9]*$/.test(value)) {
                        handleChange(e);
                      }
                    }}
                    error={errors.countOfMantra}
                  />
                </div>
                <div className="flex flex-col flex-wrap gap-0 sm:flex-row md:gap-2 lg:gap-5">
                  <div className="flex items-center gap-2">
                    <label
                      className=" whitespace-nowrap text-xs font-medium md:text-sm"
                      htmlFor="fourRegulativePrinciples"
                    >
                      Are you following regulative principles?
                    </label>
                  </div>
                  <div className="mb-3 mt-4 flex flex-wrap items-center gap-3">
                    {fourRegulativePrinciplesOptions.map((option, index) => (
                      <div key={index} className="flex  items-center gap-3">
                        <input
                          className="h-[16px] w-[16px] cursor-pointer focus:outline-none"
                          type="checkbox"
                          value={option}
                          name="fourRegulativePrinciples"
                          checked={formData?.fourRegulativePrinciples?.includes(
                            option,
                          )}
                          onChange={handleChange}
                        />
                        <label className="whitespace-nowrap text-[12px] font-medium  md:text-sm">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label="Marital status"
                    name="maritalStatus"
                    placeholder="Select Marital status"
                    options={maritalOption}
                    value={userData.maritalStatus}
                    onChange={handleChange}
                    error={errors.maritalStatus}
                  />

                  {userData.maritalStatus !== "never married" && (
                    <div>
                      <label htmlFor="childrenCount">
                        How many children do you have?
                      </label>
                      <input
                        id="childrenCount"
                        name="childrenCount"
                        value={formData.childrenCount}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[0-9]*$/.test(value)) {
                            handleChange(e);
                          }
                        }}
                        placeholder="Enter number of children"
                        className="md:w-[50% w-full rounded-lg border border-gray-200 p-2  focus:outline-none"
                        min="0"
                      />
                      {errors.childrenCount && (
                        <div className="text-red-500">
                          {errors.childrenCount}
                        </div>
                      )}
                    </div>
                  )}
                  <SelectField
                    selectType="field1"
                    className="w-full md:w-[50%]"
                    label={<RequiredLabel label="Spiritual Status" />}
                    name="spiritualStatus"
                    placeholder="Select Spiritual Status"
                    options={spiritualStatusOptions}
                    value={formData.spiritualStatus}
                    onChange={handleChange}
                    error={errors.spiritualStatus}
                  />
                </div>
                <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                  <SelectField
                    selectType="field1"
                    className="w-full "
                    label={<RequiredLabel label="Spiritual Master" />}
                    name="spiritualMaster"
                    placeholder="Select spiritual Master"
                    options={spiritualMasterOptions}
                    value={formData.spiritualMaster}
                    onChange={handleChange}
                  />
                </div>
                <InputField
                  fieldType="field1"
                  className="w-full  "
                  label={
                    <RequiredLabel label="Which ISKCON Temple are you connected to? (Please specify if it's a Yatra, Namahatta, or Temple)" />
                  }
                  name="connectedTemple"
                  type="text"
                  placeholder="Enter Your Connected ISKCON Temple"
                  value={formData.connectedTemple}
                  onChange={handleChange}
                  error={errors.connectedTemple}
                />
                <div className="mx-auto flex w-[95%] flex-col gap-5 p-5">
                  <div className="flex flex-col gap-3 ">
                    <h3 className="font-bold text-textGreen">
                      {" "}
                      {Strings.ADVANCE_BIO}{" "}
                    </h3>
                    <h1 className="pb-3 text-3xl">
                      {Strings.ADVANCE_BIO_DETAILS}
                    </h1>
                    <hr />
                  </div>
                  <div className="pb-5">
                    <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                      <InputField
                        fieldType="field1"
                        className="w-full md:w-[50%]"
                        label={<RequiredLabel label="Height (in cms)" />}
                        name="height"
                        type="number"
                        placeholder="Enter Height"
                        value={formData.height}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[0-9]*$/.test(value)) {
                            handleChange(e);
                          }
                        }}
                        error={errors.height}
                      />
                      <InputField
                        fieldType="field1"
                        className="w-full md:w-[50%]"
                        label={<RequiredLabel label="Weight (in kgs)" />}
                        name="weight"
                        type="number"
                        placeholder="Enter Weight"
                        value={formData.weight}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[0-9]*$/.test(value)) {
                            handleChange(e);
                          }
                        }}
                        error={errors.weight}
                      />
                    </div>
                    <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                      <SelectField
                        selectType="field1"
                        className="w-full md:w-[50%]"
                        label={<RequiredLabel label="Body Type" />}
                        name="bodyType"
                        placeholder="Select Body Type"
                        options={bodyTypeOptions}
                        value={formData.bodyType}
                        onChange={handleChange}
                        error={errors.bodyType}
                      />
                      <SelectField
                        selectType="field1"
                        className="w-full md:w-[50%]"
                        label={<RequiredLabel label="Complexion" />}
                        name="complexion"
                        placeholder="Select Complexion"
                        options={complexionOptions}
                        value={formData.complexion}
                        onChange={handleChange}
                        error={errors.complexion}
                      />
                    </div>
                    <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                      <InputField
                        fieldType="field1"
                        className="w-full md:w-[50%]"
                        label={
                          <RequiredLabel label="Current City Of Residence" />
                        }
                        name="currentPlace"
                        type="text"
                        placeholder="Enter City"
                        value={formData.currentPlace}
                        onChange={handleChange}
                        errors={errors.currentPlace}
                      />
                      <InputField
                        fieldType="field1"
                        className="w-full md:w-[50%]"
                        label={<RequiredLabel label="State" />}
                        name="state"
                        type="text"
                        placeholder="Enter State"
                        value={formData.state}
                        onChange={handleChange}
                        error={errors.state}
                      />
                    </div>

                    <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                      <SelectField
                        selectType="field1"
                        className="w-full md:w-[50%]"
                        label="Country"
                        name="country"
                        placeholder="Select Country"
                        options={countryOptions}
                        value={formData.country}
                        onChange={handleChange}
                      />
                      <SelectField
                        selectType="field1"
                        className="w-full md:w-[50%]"
                        label={<RequiredLabel label="Citizenship" />}
                        name="nationality"
                        placeholder="Select Nationality"
                        options={countryOptions}
                        value={formData.nationality}
                        onChange={handleChange}
                        error={errors.nationality}
                      />
                    </div>
                    <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3"></div>

                    <div className="flex flex-col gap-2 pb-3">
                      <label htmlFor="describeYourself">
                        Describe yourself:
                        {<RequiredLabel label />}
                      </label>
                      <textarea
                        placeholder="Enter describe yourself"
                        autoComplete="off"
                        name="describeYourself"
                        className="rounded-lg border border-gray-200 p-2.5  focus:outline-none"
                        onChange={handleChange}
                        value={formData.describeYourself}
                      />
                      {errors.describeYourself && (
                        <span className="text-xs text-textRed">
                          {errors.describeYourself}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col gap-3 pb-3 md:flex-row md:gap-3">
                      <div className="flex flex-col gap-1 md:w-[50%]">
                        <label htmlFor="activities">
                          List your favorite recreational activities:
                        </label>
                        <textarea
                          placeholder="List your favorite recreational activities"
                          className="rounded-lg border border-gray-200 p-2.5   focus:outline-none"
                          type="text"
                          name="activities"
                          onChange={handleChange}
                          value={formData.activities}
                        />
                      </div>
                      <div className="flex flex-col gap-1 md:w-[50%]">
                        <label htmlFor="devotionalService">
                          List your favorite devotional service:
                        </label>
                        <textarea
                          placeholder="List your favorite devotional service"
                          className="rounded-lg border border-gray-200 p-2.5 focus:outline-none"
                          name="devotionalService"
                          type="text"
                          onChange={handleChange}
                          value={formData.devotionalService}
                        />
                      </div>
                    </div>
                    <SelectField
                      selectType="field1"
                      className="w-full"
                      label={
                        <RequiredLabel label="Do you want to have children?" />
                      }
                      name="wantChildren"
                      placeholder="Select Do you want children"
                      options={wantChildrenOption}
                      value={formData.wantChildren}
                      onChange={handleChange}
                      error={errors.wantChildren}
                    />
                    <div className="flex flex-col gap-2">
                      <label htmlFor="medicalHistory">
                        Health Information
                        <span className=" text-xs text-textBlack text-opacity-70">
                          (Note : Share any relevant health details for
                          transparency and mutual understanding.)
                        </span>
                        {<RequiredLabel label />}
                      </label>
                      <textarea
                        placeholder="Enter Health Details"
                        autoComplete="off"
                        name="medicalHistory"
                        className="rounded-lg border border-gray-200 p-2.5  focus:outline-none"
                        onChange={handleChange}
                        value={formData.medicalHistory}
                        required
                      />
                    </div>
                  </div>
                  <hr className="border-2 border-textBlack" />
                </div>
                {errors && (
                  <div className="flex w-full items-center justify-center p-2">
                    <p className="text-xs text-red-500">
                      {Object.entries(errors)
                        .map(([key, value]) => `${key} is ${value}`)
                        .join(", ")}
                    </p>
                  </div>
                )}{" "}
                <div className="container mb-2 flex justify-around">
                  <button
                    onClick={() => handleNext("prev")}
                    type="button"
                    className="cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white  "
                  >
                    Previous
                  </button>
                  <button
                    type="submit"
                    className="relative cursor-pointer rounded-xl border bg-textYellow px-4 py-2  uppercase text-white  "
                    disabled={isButtonLoading}
                  >
                    {isButtonLoading ? <Spinner /> : "Next"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BasicInformation;
