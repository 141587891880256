import React, { useEffect, useState } from "react";
import Images from "../../../utils/images";
import ProfileList from "../../../Components/ProfileList/ProfileList";
import Strings from "../../../utils/Strings";
import configuration from "../../../Config/configuration";
import MessageBar, {
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../../utils/helper";
import {
  authorizedGet,
  authorizedPost,
} from "../../../Config/networkWithToken";
import Spinner from "../../../Components/Spinner/Spinner";
import Filter from "../Filter/Filter";
import Pagination from "../../../Components/Pagination/Pagination";
import { ItemsPerPages } from "../../../utils/data";
import { TbUserSearch } from "react-icons/tb";
import InputField from "../../../Components/InputField/InputField";
import AdvanceSearch from "../../../Components/AdvanceSearch/AdvanceSearch";
import { ImCancelCircle } from "react-icons/im";
import { IoIosSearch } from "react-icons/io";
import { data } from "currency-codes";
import { useLocation } from "react-router-dom";

const Matrimony = () => {
  const [activeTab, setActiveTab] = useState(
    parseInt(localStorage.getItem("activeTab")) || 0,
  );
  const [maleList, setMaleList] = useState([]);
  const [femaleList, setFemaleList] = useState([]);
  const [allUserList, setAllUserList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [showTabs, setShowTabs] = useState(true);
  const [noMaleUsersMessage, setNoMaleUsersMessage] = useState("");
  const [noFemaleUsersMessage, setNoFemaleUsersMessage] = useState("");
  const [Role, setRole] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = Number(searchParams.get("page")) || 1;
  const [currentPageAll, setCurrentPageAll] = useState(currentPage);
  const [currentPageMale, setCurrentPageMale] = useState(currentPage);
  const [currentPageFemale, setCurrentPageFemale] = useState(currentPage);
  const [currentFilterPage, setCurrentFilterPage] = useState(currentPage);
  const [currentSearchPage, setCurrentSearchPage] = useState(currentPage);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [searchUserList, setSearchUserlist] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [mainActiveTab, setMainActiveTab] = useState(
    parseInt(localStorage.getItem("mainActiveTab")) || 0,
  );
  const [sortOrder, SetSortOrder] = useState(
    localStorage.getItem("sortOrder") || "desc",
  );
  const isFilterApplied = localStorage.getItem("isFiltered");
  const itemsPerPage = ItemsPerPages;

  useEffect(() => {
    const isFilterApplied = JSON.parse(localStorage.getItem("isFiltered"));
    const storedFilteredData = JSON.parse(localStorage.getItem("filteredData"));
    const storedSearchResults = JSON.parse(
      localStorage.getItem("searchResults"),
    );

    if (storedSearchResults) {
      setSearchUserlist(storedSearchResults);
    } else if (isFilterApplied && storedFilteredData) {
      setFilteredData(storedFilteredData);
    } else {
      fetchAllUserData();
    }
    fetchRole();
  }, []);
  // useEffect(() => {
  //   localStorage.setItem("mainActiveTab", mainActiveTab);
  //   localStorage.setItem("activeTab", activeTab);
  //   let selectedUsers =
  //     mainActiveTab === 1 ? handleFilterByStatus(allUsers) : allUsers;
  //   setAllUserList(selectedUsers);

  //   if (activeTab === 1) {
  //     setMaleList(filterByGender(selectedUsers, "male")); // Filters based on All/Active Users
  //   } else if (activeTab === 2) {
  //     setFemaleList(filterByGender(selectedUsers, "female"));
  //   }
  //   const storedSortOrder = localStorage.getItem("sortOrder") || "desc";
  //   SetSortOrder(storedSortOrder);

  //   if (allUserList.length > 0) {
  //      handleSort(storedSortOrder);
  //   }

  // }, [mainActiveTab, activeTab, allUsers]);
  useEffect(() => {
    localStorage.setItem("mainActiveTab", mainActiveTab);
    localStorage.setItem("activeTab", activeTab);

    let selectedUsers =
      mainActiveTab === 1 ? handleFilterByStatus(allUsers) : allUsers;

    const storedSortOrder = localStorage.getItem("sortOrder") || "desc";
    SetSortOrder(storedSortOrder);

    let sortedUsers = [...selectedUsers].sort((a, b) =>
      storedSortOrder === "asc"
        ? new Date(a.registerDate) - new Date(b.registerDate)
        : new Date(b.registerDate) - new Date(a.registerDate),
    );

    if (JSON.stringify(sortedUsers) !== JSON.stringify(allUserList)) {
      setAllUserList(sortedUsers);
    }

    if (activeTab === 1) {
      const filteredMales = filterByGender(sortedUsers, "male");
      if (JSON.stringify(filteredMales) !== JSON.stringify(maleList)) {
        setMaleList(filteredMales);
      }
    } else if (activeTab === 2) {
      const filteredFemales = filterByGender(sortedUsers, "female");
      if (JSON.stringify(filteredFemales) !== JSON.stringify(femaleList)) {
        setFemaleList(filteredFemales);
      }
    }
  }, [mainActiveTab, activeTab, allUsers]);

  const fetchRole = async () => {
    const role = getValueFromLocalStorage(localStorageKey.USERDATA, "role");
    if (role) {
      setRole(role);
    }
  };
  const mainTabList = [{ name: "All Users" }, { name: "Active Users" }];

  const tabList = [{ name: "Users" }, { name: "Male" }, { name: "Female" }];

  const fetchAllUserData = async () => {
    try {
      setInitialLoading(true);
      const res = await authorizedGet(configuration.apis.filter + "", config);
      const response = res?.data;
      if (response?.status) {
        setAllUserList(response?.data);
        setAllUsers(response?.data);
        // const storedSortOrder = localStorage.getItem("sortOrder") || "desc";
        // handleSort(storedSortOrder);
      }
      if (response?.data?.length === 0) {
        setNoMaleUsersMessage("No users available.");
      } else {
        setNoMaleUsersMessage("");
      }
    } catch (err) {
      console.log("Error fetching all user data:", err);
    } finally {
      setInitialLoading(false);
    }
  };

  const filterByGender = (data, gender) => {
    if (gender) {
      const filtered = data.filter((user) => user.gender === gender);
      return filtered;
    }
    return data;
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    setShowTabs(true);
    setCurrentPageAll(1);
    setCurrentPageFemale(1);
    setCurrentPageMale(1);

    let selectedUsers =
      mainActiveTab === 1 ? handleFilterByStatus(allUsers) : allUsers;

    if (index === 1) {
      setMaleList(filterByGender(selectedUsers, "male"));
    } else if (index === 2) {
      setFemaleList(filterByGender(selectedUsers, "female"));
    } else {
      setMaleList([]);
      setFemaleList([]);
    }
  };

  const handleFilterByStatus = (data) => {
    if (data) {
      const response = data.filter((user) => {
        if (user.hasOwnProperty("isActive")) {
          return user.isActive === true && user.profileCompleted === true;
        } else {
          return user.profileCompleted === true;
        }
      });
      console.log(response);
      return response;
    }
  };
    

  const handleMainTabClick = (index) => {
    setMainActiveTab(index);
    setActiveTab(0);
    setMaleList([]);
    setFemaleList([]);
    setShowTabs(true);
    setCurrentPageAll(1);
    setCurrentPageFemale(1);
    setCurrentPageMale(1);
    SetSortOrder("desc");

    let selectedUsers = index === 1 ? handleFilterByStatus(allUsers) : allUsers;
    setAllUserList(selectedUsers);
  };
  const handleApplyFilters = async (queryParams) => {
    try {
      setLoading(true);
      setCurrentFilterPage(1);
      let res = "";
      if (activeTab === 0) {
        if (mainActiveTab === 0) {
          res = await authorizedGet(
            `${configuration.apis.filter}?${queryParams}`,
            config,
          );
        } else {
          res = await authorizedGet(
            `${configuration.apis.filter}?status=Active&${queryParams}`,
            config,
          );
        }
      } else if (activeTab === 1) {
        if (mainActiveTab === 0) {
          res = await authorizedGet(
            `${configuration.apis.filter}?gender=male&${queryParams}`,
            config,
          );
        } else {
          res = await authorizedGet(
            `${configuration.apis.filter}?status=Active&gender=male&${queryParams}`,
            config,
          );
        }
      } else if (activeTab === 2) {
        if (mainActiveTab === 0) {
          res = await authorizedGet(
            `${configuration.apis.filter}?gender=female&${queryParams}`,
            config,
          );
        } else {
          res = await authorizedGet(
            `${configuration.apis.filter}?status=Active&gender=female&${queryParams}`,
            config,
          );
        }
      }
      // const res = await authorizedGet(`${configuration.apis.filter}?status=active&${queryParams}`, config);

      const response = res?.data;

      if (response?.status) {
        let mappedResults = response?.data;
        setFilteredData(mappedResults);
        localStorage.setItem("isFiltered", JSON.stringify(true));
        localStorage.setItem("filteredData", JSON.stringify(mappedResults));
        setNoResultsMessage("");
      } else {
        setNoResultsMessage("No users found matching your criteria.");
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
      // console.log(error);
    }
    setLoading(false);
  };

  const handleClearFilter = () => {
    setLoading(true);
    setCurrentFilterPage(1);
    setCurrentSearchPage(1);
    setCurrentPageAll(1);
    setCurrentPageMale(1);
    setCurrentPageFemale(1);
    try {
      // Remove stored filter/search data
      localStorage.removeItem("isFiltered");
      localStorage.removeItem("filteredData");
      localStorage.removeItem("searchResults");

      // Reset filtered data and user list
      setFilteredData([]);
      setSearchUserlist([]);
      setNoResultsMessage("");

      setShowTabs(true);

      if (!allUserList || allUserList.length === 0) {
        fetchAllUserData();
      }
    } catch (error) {
      console.error("Clear Filter Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (value) => {
    SetSortOrder(value);
    localStorage.setItem("sortOrder", value);
    let sortedList = [...allUserList].sort((a, b) =>
      value === "asc"
        ? new Date(a.registerDate) - new Date(b.registerDate)
        : new Date(b.registerDate) - new Date(a.registerDate)
    );
    setAllUserList(sortedList);
    if (activeTab === 1) {
      setMaleList(filterByGender(sortedList, "male"));
    } else if (activeTab === 2) {
      setFemaleList(filterByGender(sortedList, "female"));
    }
  };

  const toggleFilter = () => setShowFilter(!showFilter);

  const totalPagesAll = Math.ceil(allUserList.length / itemsPerPage);
  const totalPagesMale = Math.ceil(maleList.length / itemsPerPage);
  const totalPagesFemale = Math.ceil(femaleList.length / itemsPerPage);
  const totalFilterPages = Math.ceil(filteredData.length / itemsPerPage);
  const totalSearchPages = Math.ceil(searchUserList.length / itemsPerPage);

  const currentAllUsers = allUserList.slice(
    (currentPageAll - 1) * itemsPerPage,
    currentPageAll * itemsPerPage,
  );

  const currentMaleUsers = maleList.slice(
    (currentPageMale - 1) * itemsPerPage,
    currentPageMale * itemsPerPage,
  );

  const currentFemaleUsers = femaleList.slice(
    (currentPageFemale - 1) * itemsPerPage,
    currentPageFemale * itemsPerPage,
  );

  const currentFiletrUsers = filteredData.slice(
    (currentFilterPage - 1) * itemsPerPage,
    currentFilterPage * itemsPerPage,
  );

  const currentSearchUsers = searchUserList.slice(
    (currentSearchPage - 1) * itemsPerPage,
    currentSearchPage * itemsPerPage,
  );

  const handleAdvanceSearch = (value) => {
    if (value.length >= 3) {
      setLoading(true);
      try {
        // Retrieve filtered data from localStorage or fallback to allUsers
        let storedFilteredData =
          JSON.parse(localStorage.getItem("filteredData")) || allUsers;
        let filteredUsers = [];

        // Apply search within the stored filtered data
        if (mainActiveTab === 0) {
          if (activeTab === 0) {
            filteredUsers = allUsers?.filter((user) =>
              user?.name?.toLowerCase()?.includes(value?.toLowerCase()),
            );
          } else if (activeTab === 1) {
            filteredUsers = maleList.filter(
              (user) =>
                user?.gender === "male" &&
                user?.name?.toLowerCase().includes(value.toLowerCase()),
            );
          } else if (activeTab === 2) {
            filteredUsers = femaleList.filter(
              (user) =>
                user?.gender === "female" &&
                user?.name?.toLowerCase().includes(value.toLowerCase()),
            );
          }
        } else if (mainActiveTab === 1) {
          if (activeTab === 0) {
            filteredUsers = handleFilterByStatus(allUsers)?.filter((user) =>
              user?.name?.toLowerCase()?.includes(value?.toLowerCase()),
            );
          } else if (activeTab === 1) {
            filteredUsers = maleList.filter(
              (user) =>
                user?.gender === "male" &&
                user?.name?.toLowerCase().includes(value.toLowerCase()),
            );
          } else if (activeTab === 2) {
            filteredUsers = femaleList.filter(
              (user) =>
                user?.gender === "female" &&
                user?.name?.toLowerCase().includes(value.toLowerCase()),
            );
          }
        }
        //handleFilterByStatus(allUsers)
        if (filteredUsers.length > 0) {
          setSearchUserlist(filteredUsers);
          setNoResultsMessage("");
          localStorage.setItem("searchResults", JSON.stringify(filteredUsers)); // Persist search results
        } else {
          setNoResultsMessage("No users found matching your criteria.");
        }
      } catch (error) {
        console.error("Search Error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible);
    setSearchUserlist([]);
    setNoResultsMessage("");
    setShowTabs(true);
    setCurrentSearchPage(1);
  };

  return (
    <div className="mt-7 flex w-full flex-col px-4 md:mt-0">
      <div className="mb-3 h-auto w-full rounded-lg bg-bgWhite p-2 shadow-md">
        {filteredData.length === 0 && searchUserList.length === 0 && (
          <div className="flex flex-row items-center justify-between">
            <ul className="flex flex-row">
              {mainTabList.map((list, index) => (
                <li
                  key={index}
                  className={`font-jost cursor-pointer px-4 py-2 text-center text-[10px] text-textGreen  transition-all ms:text-[12px] xxs:text-[14px] 
                    ${initialLoading ? "cursor-not-allowed" : ""}
                    ${
                      mainActiveTab === index
                        ? "border-b-2 border-textYellow font-semibold text-textYellow"
                        : "hover:bg-gray-100 hover:text-textYellow"
                    }`}
                  onClick={() => !initialLoading && handleMainTabClick(index)}
                >
                  {list.name}
                </li>
              ))}
            </ul>
            <div
              className={`flex flex-col gap-1 text-[10px] ms:text-[12px] xxs:text-[14px] md:flex-row ${initialLoading ? "cursor-not-allowed" : ""}`}
            >
              <label htmlFor="sort" className="font-jost text-textGreen">
                Sort by Register Date :
              </label>
              <select
                onChange={(e) => handleSort(e?.target?.value)}
                name="sort"
                id="sort"
                value={sortOrder}
                className="w-[100px] rounded-lg border-[1px] border-gray-500 px-2 outline-none xxs:w-[150px] sm:w-[200px]"
              >
                <option value="desc">Latest Registered Users</option>
                <option value="asc">Oldest Registered Users</option>
              </select>
            </div>
          </div>
        )}

        {showTabs && (
          <div className="font-helvetica flex flex-col items-center gap-2 text-textGreen sm:flex-row sm:justify-between">
            {filteredData.length === 0 && searchUserList.length === 0 ? (
              <ul className="flex border-b border-gray-300 md:flex-row">
                {tabList.map((list, index) => (
                  <li
                    key={index}
                    className={`font-jost cursor-pointer px-4 py-2 text-center text-textGreen transition-all sm:text-xs md:text-base 
                    ${initialLoading ? "cursor-not-allowed" : ""}
                    ${
                      activeTab === index
                        ? "border-b-2 border-textYellow font-semibold text-textYellow"
                        : "hover:bg-gray-100 hover:text-textYellow"
                    }`}
                    onClick={() => !initialLoading && handleTabClick(index)}
                  >
                    {list.name}
                  </li>
                ))}
              </ul>
            ) : (
              <>
                {filteredData.length > 0 ? (
                  <div className="mt-4">
                    <p className="text-md berkshire text-textGreen">
                      Filtered Result Users:
                    </p>
                  </div>
                ) : (
                  <div className="mt-4">
                    <p className="text-md berkshire text-textGreen">
                      Searched Users:
                    </p>
                  </div>
                )}
              </>
            )}
            <div
              className={`flex ${isSearchBarVisible ? "flex-col xs:flex-row" : "flex-row"} items-center justify-center gap-2`}
            >
              {isSearchBarVisible && !isFilterApplied && (
                <div className="flex flex-col items-center justify-center gap-1 border-x-gray-400 p-2 xs:flex-row">
                  <AdvanceSearch onSearchValue={handleAdvanceSearch} />
                </div>
              )}
              <div className="flex items-center justify-center ">
                {isSearchBarVisible && !isFilterApplied ? (
                  <ImCancelCircle
                    style={{
                      fontSize: "25px",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleClearFilter();
                      handleSearchBar();
                    }}
                  />
                ) : (
                  !isFilterApplied &&
                  !initialLoading &&
                  !loading &&
                  !searchUserList.length > 0 && (
                    <IoIosSearch
                      style={{
                        fontSize: "30px",
                        color: "GrayText",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSearchBar()}
                    />
                  )
                )}
              </div>
              <div className="flex flex-row justify-between gap-4 p-1">
                <div className="flex flex-row justify-between gap-4 p-1">
                  {searchUserList.length > 0 && (
                    <div>
                      <button
                        className="font-jost rounded-lg bg-red-600 px-4 py-2 text-xs text-bgWhite xl:text-base"
                        onClick={() => {
                          handleClearFilter();
                          handleSearchBar();
                          setIsSearchBarVisible(false);
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </div>
                <div>
                  {!searchUserList.length > 0 && (
                    <div className="flex gap-4">
                      <button
                        className="font-jost rounded-lg bg-textYellow px-4 py-2 text-[12px] text-bgWhite xs:text-[10px] md:text-[12px] xl:text-base"
                        onClick={toggleFilter}
                      >
                        Show Filter
                      </button>
                      <button
                        className="font-jost rounded-lg bg-red-600 px-4 py-2 text-xs text-bgWhite xl:text-base"
                        onClick={handleClearFilter}
                      >
                        Clear Filter
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {initialLoading || loading ? (
          <div className="flex h-screen items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            {noResultsMessage ? (
              <div className="mt-4 text-center text-red-600">
                {noResultsMessage}
              </div>
            ) : (
              <>
                {activeTab === 1 && noMaleUsersMessage && (
                  <div className="mt-4 text-center text-red-600">
                    {noMaleUsersMessage}
                  </div>
                )}

                {activeTab === 2 && noFemaleUsersMessage && (
                  <div className="mt-4 text-center text-red-600">
                    {noFemaleUsersMessage}
                  </div>
                )}

                {filteredData.length > 0 || searchUserList.length > 0 ? (
                  <>
                    {filteredData.length > 0 ? (
                      <div className="mt-4">
                        {currentFiletrUsers.map((item, index) => (
                          <ProfileList
                            key={index}
                            role={Role}
                            userData={item}
                          />
                        ))}
                        <Pagination
                          currentPage={currentFilterPage}
                          totalPages={totalFilterPages}
                          handlePageChange={setCurrentFilterPage}
                        />
                      </div>
                    ) : (
                      <div className="mt-4">
                        {currentSearchUsers.map((item, index) => (
                          <ProfileList
                            key={index}
                            role={Role}
                            userData={item}
                          />
                        ))}
                        <Pagination
                          currentPage={currentSearchPage}
                          totalPages={totalSearchPages}
                          handlePageChange={setCurrentSearchPage}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {mainActiveTab === 0 && <div></div>}
                    {activeTab === 0 && (
                      <>
                        {allUserList.length > 0 ? (
                          <>
                            {currentAllUsers.map((item, index) => (
                              <ProfileList
                                key={index}
                                role={Role}
                                userData={item}
                              />
                            ))}
                            <Pagination
                              currentPage={currentPageAll}
                              totalPages={totalPagesAll}
                              handlePageChange={setCurrentPageAll}
                            />
                          </>
                        ) : (
                          <div className="flex flex-col items-center justify-center ">
                            <p className="text-center">No Users found!</p>
                          </div>
                        )}
                      </>
                    )}
                    {activeTab === 1 && (
                      <>
                        {maleList.length > 0 ? (
                          <>
                            {currentMaleUsers.map((item, index) => (
                              <ProfileList
                                key={index}
                                role={Role}
                                userData={item}
                              />
                            ))}
                            <Pagination
                              currentPage={currentPageMale}
                              totalPages={totalPagesMale}
                              handlePageChange={setCurrentPageMale}
                            />
                          </>
                        ) : (
                          <div className="flex flex-col items-center justify-center ">
                            <p className="text-center">No Male Users found!</p>
                          </div>
                        )}
                      </>
                    )}

                    {activeTab === 2 && (
                      <>
                        {femaleList.length > 0 ? (
                          <>
                            {currentFemaleUsers.map((item, index) => (
                              <ProfileList
                                key={index}
                                role={Role}
                                userData={item}
                              />
                            ))}
                            <Pagination
                              currentPage={currentPageFemale}
                              totalPages={totalPagesFemale}
                              handlePageChange={setCurrentPageFemale}
                            />
                          </>
                        ) : (
                          <div className="flex flex-col items-center justify-center ">
                            <p className="text-center">
                              No female users found!
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {showFilter && (
          <Filter
            handleShowFilter={toggleFilter}
            onApplyFilters={handleApplyFilters}
          />
        )}
      </div>
      <MessageBar />
    </div>
  );
};

export default Matrimony;
