import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../Components/Spinner/Spinner";
import { MdDeleteSweep } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import {
  authorizedDel,
  authorizedGet,
  authorizedPost,
  authorizedPut,
} from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import MessageBar, {
  config,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import Strings from "../../utils/Strings";
import { useNavigate } from "react-router-dom";

const Notes = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [saveNote, setSaveNote] = useState("");
  const [updateIndex, setUpdateIndex] = useState(0);
  const [isUpdateButtonVisible, setIsUpdateButtonVisible] = useState(false);
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [noteError, setNoteError] = useState("");
  const [noteToDeleteIndex, setNoteToDeleteIndex] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchGetNotes();
  }, []);

  const handleDeleteUser = async () => {
    setDeleting(true);
    try {
      const res = await authorizedDel(
        `${configuration.apis.deleteUser}/${userId}`,
        config,
      );
      const response = res?.data;
      if (response?.status) {
        successNotify(response?.message);
        navigate("/matrimony");
      }
    } catch (error) {
      errorNotify(
        error?.response?.message || "An error occurred while deleting the user",
      );
    } finally {
      setDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  //Function for get note API call
  const fetchGetNotes = async () => {
    try {
      const res = await authorizedGet(
        `${configuration.apis.getNotes}?userId=${userId}&adminId=${adminId}`,
        config,
      );
      const response = res?.data?.data;
      if (response) {
        setNotes(response);
      }
    } catch (error) {
      console.log("Error fetching get Notes :" + error);
    }
    setLoading(false);
  };

  //Function for add note API call
  const handleAddNotes = async () => {
    if (saveNote.trim() === "") {
      setNoteError("Please add some details");
      return;
    }
    try {
      const data = {
        userId: userId,
        adminId: adminId,
        notes: saveNote,
      };
      setUpdateLoading(true);
      const res = await authorizedPost(
        configuration.apis.addNotes,
        data,
        config,
      );
      const response = res?.data;
      if (res?.status) {
        successNotify(response?.message);
      } else {
        errorNotify(response?.message);
      }
      if (response?.data) {
        setNotes(response.data);
      }
    } catch (error) {
      console.log("Error adding note:", error);
    } finally {
      setUpdateLoading(false);
      closeModal();
    }
  };

  //Function for update API call
  const handleUpdateNotes = async () => {
    if (saveNote.trim() === "") {
      setNoteError("Please add some details");
      return;
    }
    try {
      setUpdateLoading(true);
      const data = {
        noteId: notes[updateIndex]._id,
        notes: saveNote,
      };
      const res = await authorizedPut(
        configuration.apis.updateNote,
        data,
        config,
      );
      const response = res?.data;
      if (res?.status) {
        successNotify(response?.message);
        const updatedNotes = [...notes];
        updatedNotes[updateIndex].notes = response?.data?.notes;
        setNotes(updatedNotes);
      } else {
        errorNotify(response.message);
      }
    } catch (error) {
      console.log("Error updating note:", error);
    } finally {
      setUpdateLoading(false);
      setIsModalVisible(false);
      setIsUpdateButtonVisible(false);
      setSaveNote("");
    }
  };

  //Function to open the modal
  const handleSubmit = () => {
    setIsModalVisible(true);
  };

  //Function to update the note data
  const handleNoteChange = (event) => {
    setSaveNote(event.target.value);
    if (noteError) setNoteError(""); // clear error on change
  };

  //Function to remove or delete the note
  const removeNote = async (index) => {
    try {
      setDeleting(true);
      const noteId = notes[index]._id;
      const res = await authorizedDel(
        `${configuration.apis.deleteNote}?noteId=${noteId}`,
        config,
      );
      const response = res?.data;
      if (response?.status) {
        successNotify(response?.message);
        const updatedNotes = notes.filter((_, i) => i !== index);
        setNotes(updatedNotes);
      } else {
        errorNotify(response?.message);
      }
    } catch (error) {
      console.log("Error deleting note:", error);
    } finally {
      setDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  //Function to update the note
  const updatedNote = (index) => {
    setIsModalVisible(true);
    setSaveNote(notes[index].notes);
    setIsUpdateButtonVisible(true);
    setUpdateIndex(index);
  };

  //Function to close the modal
  const closeModal = () => {
    setIsModalVisible(false);
    setIsUpdateButtonVisible(false);
    setSaveNote("");
  };

  const confirmDeleteNote = async () => {
    if (noteToDeleteIndex !== null) {
      await removeNote(noteToDeleteIndex);
      setNoteToDeleteIndex(null); // clear after delete
    }
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <div>
        {loading ? (
          <div className="loader flex h-screen items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className=" mx-auto flex w-full flex-row justify-center bg-bgWhite p-5">
            <div className="flex w-[85%] flex-col ">
              <h2 className="mb-6 text-2xl text-gray-800">Notes</h2>
              <div className="border-blue mb-5 h-auto w-full rounded-lg bg-bgWhite p-4 shadow-sm">
                {notes.length !== 0 ? (
                  <ul className="flex h-full w-full flex-col gap-5">
                    {notes.map((list, index) => (
                      <li
                        key={index}
                        className="flex h-full w-full flex-row justify-between font-medium text-black"
                      >
                        {list.notes}
                        <div className="flex flex-row gap-3">
                          <p className="text-xs text-gray-400">
                            {" "}
                            created by {notes[index]?.admin?.name}
                          </p>
                          <div
                            className="update icon cursor-pointer text-sky-300 "
                            onClick={() => updatedNote(index)}
                          >
                            <FaEdit />{" "}
                          </div>
                          <div
                            className="delete icon cursor-pointer text-textRed "
                            onClick={() => {
                              setNoteToDeleteIndex(index);
                              setShowDeleteConfirmation(true);
                            }}
                          >
                            <MdDeleteSweep />{" "}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span> No details available </span>
                )}
              </div>
            </div>
            <div className="flex w-[15%] flex-col items-center rounded-sm ">
              <button
                className="h-10 w-20 rounded-lg bg-textBlue text-xs font-medium text-bgWhite md:w-28 md:text-lg"
                onClick={handleSubmit}
              >
                Add Notes
              </button>
            </div>
          </div>
        )}

        {isModalVisible && (
          <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
            <div className="relative w-[50%] items-center justify-center rounded-lg bg-white p-8 shadow-lg">
              <div className="flex flex-col justify-center">
                <h2 className="mb-4 text-lg font-semibold">Add a Note</h2>
                <textarea
                  className="mb-4 w-full rounded border border-gray-300 p-2"
                  rows="4"
                  value={saveNote}
                  onChange={handleNoteChange}
                  placeholder="Enter your note here..."
                />
                {noteError && (
                  <p className="mb-2 text-sm text-red-500">{noteError}</p>
                )}
                <div className="flex justify-end gap-2">
                  <button
                    className="rounded bg-gray-300 px-1 py-0.5 text-black md:px-4 md:py-2"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  {isUpdateButtonVisible ? (
                    <button
                      className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                      onClick={handleUpdateNotes}
                      disabled={updateLoading}
                    >
                      {updateLoading ? <Spinner size="small" /> : "Update"}
                    </button>
                  ) : (
                    <button
                      className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                      onClick={handleAddNotes}
                      disabled={updateLoading}
                    >
                      {updateLoading ? <Spinner size="small" /> : "Save"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Delete Confirmation Modal */}
        {showDeleteConfirmation && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-[90%] rounded-lg bg-white p-4 shadow-lg sm:w-[60%] md:w-[40%]">
              <p className="mb-4 text-center text-sm sm:text-base">
                {Strings.DELETE_NOTE}
              </p>
              <div className="flex justify-around">
                <button
                  onClick={confirmDeleteNote}
                  className="rounded bg-red-500 px-4 py-2 text-white"
                  disabled={deleting}
                >
                  {deleting ? <Spinner size="small" /> : "Yes, Delete"}
                </button>
                <button
                  onClick={() => {
                    setNoteToDeleteIndex(null);
                    setShowDeleteConfirmation(false);
                  }}
                  className="rounded bg-gray-300 px-4 py-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <MessageBar />
    </>
  );
};

export default Notes;
