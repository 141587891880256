import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  authorizedGet,
  authorizedPost,
} from "../../../Config/networkWithToken";
import configuration from "../../../Config/configuration";
import MessageBar, {
  config,
  DOBASEURL,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../../utils/helper";
import Spinner from "../../../Components/Spinner/Spinner";
import Strings from "../../../utils/Strings";
import { MdDoubleArrow } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa6";
import { FaUserTimes } from "react-icons/fa";
import "./UserRequest.css";
import Images from "../../../utils/images";
import { ImCancelCircle } from "react-icons/im";
import { IoIosSearch } from "react-icons/io";
import AdvanceSearch from "../../../Components/AdvanceSearch/AdvanceSearch";

const RequestCards = ({
  request,
  activeTab,
  index,
  adminId,
  setUserRequestData,
  setAcceptedRequest,
  setRejectedRequest,
}) => {
  const [loadingAcceptRequestId, setLoadingAcceptRequestId] = useState(null);
  const [loadingDenyRequestId, setLoadingDenyRequestId] = useState(null);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  const [requestedId, setRequestedId] = useState(null);

  //Admin Request Approval
  const handleApproval = async (decision, requestId) => {
    try {
      if (decision === "approve") {
        setLoadingAcceptRequestId(requestId);
      } else {
        setLoadingDenyRequestId(requestId);
      }

      const data = {
        adminId: adminId,
        requestId: requestId,
        action: decision === "approve" ? Strings.APPROVE : Strings.REJECT,
        comments: comment,
      };

      const res = await authorizedPost(
        configuration.apis.adminApproval,
        data,
        config,
      );
      const response = res?.data?.data;
      if (res?.data?.status) {
        successNotify(res?.data?.message);
        const pending = [];
        const accepted = [];
        const rejected = [];
        response.forEach((value) => {
          if (value?.requestStatus === "Pending") {
            pending.push(value);
          } else if (value?.requestStatus === "Accepted") {
            accepted.push(value);
          } else if (value?.requestStatus === "Rejected") {
            rejected.push(value);
          }
        });
        setUserRequestData(pending);
        setAcceptedRequest(accepted);
        setRejectedRequest(rejected);
      } else {
        errorNotify(res?.data?.message);
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
      // console.log(error);
    } finally {
      setLoadingAcceptRequestId(null);
      setLoadingDenyRequestId(null);
    }
  };

  const handleNoteChange = (event) => {
    setError(false);
    setComment(event.target.value);
  };

  const closeModal = () => {
    setComment("");
    setError(false);
    setIsCommentSectionOpen(false);
  };

  const handleAction = (requestedId, decision) => {
    setRequestedId(requestedId);
    if (decision === "approve") {
      handleApproval(decision, requestedId);
    } else {
      setIsCommentSectionOpen(true);
    }
  };

  const handleSaveComment = () => {
    if (comment.length < 15) {
      setError(true);
    } else {
      handleApproval("reject", requestedId);
      setIsCommentSectionOpen(false);
      setError(false);
      setComment("");
    }
  };

  return (
    <div
      key={index}
      className="font-jost relative flex w-full flex-col items-center gap-2 rounded-xl bg-white p-2 shadow-md md:w-[30%] md:justify-around"
    >
      <div className="flex w-full flex-col items-center gap-2 p-2 md:justify-around xl:flex-row">
        <div className="flex flex-row justify-around">
          {/* Requested User's Profile */}
          <div className="flex flex-col items-center text-center">
            <Link to={`/request-detail/${request?.requestedUser?._id}`}>
              <img
                src={
                  request?.requestedUser?.images.length > 0
                    ? DOBASEURL + request?.requestedUser?.images[0]
                    : request?.requestedUser?.gender === "male"
                      ? Images.MEN
                      : Images.WOMEN
                  // DOBASEURL + request?.requestedUser?.images[0]
                }
                className="h-20 w-20 cursor-pointer rounded-full border-4 border-yellow-200"
                alt="Requested User"
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
              />
            </Link>
            <span className="scrollbar-hide relative w-[80px] overflow-x-auto whitespace-nowrap text-gray-700">
              {request?.requestedUser?.name}
            </span>
          </div>
          <div className="flex">
            <MdDoubleArrow
              className="aria-hidden:true mt-5"
              style={{ fontSize: "40px", color: "#ffbb33" }}
            />
          </div>
          {/* User Profile */}
          <div className="flex flex-col items-center text-center">
            <Link to={`/request-detail/${request?.user?._id}`}>
              <img
                src={
                  request?.user?.images.length > 0
                    ? DOBASEURL + request?.user?.images[0]
                    : request?.user?.gender === "male"
                      ? Images.MEN
                      : Images.WOMEN
                  // DOBASEURL + request?.user?.images[0]
                }
                className="h-20 w-20 cursor-pointer rounded-full border-4 border-yellow-200"
                alt="User"
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
              />
            </Link>
            <span className="scrollbar-hide relative w-[80px] overflow-x-auto whitespace-nowrap text-gray-700">
              {request?.user?.name}
            </span>
          </div>
        </div>
        {/* Accept and Deny Buttons */}
        {activeTab === 0 && (
          <div className="flex w-full flex-row items-center justify-center gap-3 lg:w-[20%] xl:flex-col">
            <button
              className={`right-24 top-4 rounded-full bg-green-500 px-2 py-2 text-xs text-white ${loadingAcceptRequestId === request._id ? "cursor-not-allowed opacity-50" : ""}`}
              onClick={() => handleAction(request?._id, "approve")}
              disabled={loadingAcceptRequestId === request?._id}
            >
              {loadingAcceptRequestId === request?._id ? (
                <Spinner />
              ) : (
                <FaUserCheck />
              )}
            </button>
            <button
              className={`right-4 top-4 rounded-full bg-red-500 px-2 py-2 text-xs text-white ${loadingDenyRequestId === request._id ? "cursor-not-allowed opacity-50" : ""}`}
              onClick={() => handleAction(request?._id, "reject")}
              disabled={loadingDenyRequestId === request?._id}
            >
              {loadingDenyRequestId === request?._id ? (
                <Spinner />
              ) : (
                <FaUserTimes />
              )}
            </button>
          </div>
        )}
        {activeTab === 2 && (
          <button
            className={`absolute right-2 top-2 rounded-full bg-blue-500 p-2 text-xs text-white shadow-md transition duration-300 hover:bg-blue-200 ${
              loadingDenyRequestId === request._id
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
            onClick={() => handleAction(request?._id, "approve")}
            disabled={loadingDenyRequestId === request?._id}
          >
            {loadingDenyRequestId === request?._id ? (
              <Spinner />
            ) : (
              <FaUserCheck />
            )}
          </button>
        )}
      </div>
      <p className="text-xs text-gray-400">Click profile to see full details</p>
      {isCommentSectionOpen && (
        <div className="popup-container fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-[80%] items-center justify-center rounded-lg bg-white p-8 shadow-lg md:w-[50%]">
            <div className="flex flex-row gap-1">
              <h2 className="mb-4 text-lg font-semibold">
                Add a reason for reject
              </h2>
              <p className="text-sm font-semibold text-textRed">*</p>
            </div>
            <textarea
              className="w-full rounded border border-gray-300 p-2 "
              rows="4"
              onChange={handleNoteChange}
              placeholder="Enter your comments..."
            />
            {error && (
              <span className="mt-0 text-xs text-textRed">
                {comment.length < 15
                  ? "Comment must be at least 15 characters"
                  : "Required"}
              </span>
            )}
            <div className="mt-4 flex justify-end gap-2">
              <button
                className="rounded bg-gray-300 px-1 py-0.5 text-black md:px-4 md:py-2"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className="rounded bg-blue-500 px-1 py-0.5 text-white md:px-4 md:py-2"
                onClick={handleSaveComment}
              >
                Send response
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UserRequest = () => {
  const adminId = getValueFromLocalStorage(localStorageKey.USERDATA, "adminId");
  const [userRequestData, setUserRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [acceptedRequest, setAcceptedRequest] = useState([]);
  const [rejectedRequest, setRejectedRequest] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [filter, setFilter] = useState("all");
  const [requests, setRequests] = useState([]);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    getUserRequest();
  }, []);

  //Get all the Requests
  const getUserRequest = async () => {
    setLoading(true);
    try {
      const res = await authorizedGet(
        `${configuration.apis.adminApprovalPending}?adminId=${adminId}`,
        config,
      );
      const response = res?.data?.data;
      if (res?.data?.status) {
        const pending = [];
        const accepted = [];
        const rejected = [];
        setRequests(response);
        response.forEach((value) => {
          if (value?.requestStatus === "Pending") {
            pending.push(value);
          } else if (value?.requestStatus === "Accepted") {
            accepted.push(value);
          } else if (value?.requestStatus === "Rejected") {
            rejected.push(value);
          }
        });
        setUserRequestData(pending);
        setAcceptedRequest(accepted);
        setRejectedRequest(rejected);
      } else {
        errorNotify(response?.message);
      }
    } catch (error) {
      errorNotify(Strings.ERROR);
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleAdvanceSearch = (searchValue) => {
    const query = searchValue.toLowerCase().trim();
    setSearchQuery(query);
    let search = [];

    if (activeTab === 0) {
      search = userRequestData?.filter((user) => {
        const userName = user?.user?.name?.toLowerCase() || "";
        const requestedUserName =
          user?.requestedUser?.name?.toLowerCase() || "";
        return userName.includes(query) || requestedUserName.includes(query);
      });
      setUserRequestData(search);
      setSearchData(search);
    } else if (activeTab === 1) {
      search = acceptedRequest?.filter((user) => {
        const userName = user?.user?.name?.toLowerCase() || "";
        const requestedUserName =
          user?.requestedUser?.name?.toLowerCase() || "";

        return userName.includes(query) || requestedUserName.includes(query);
      });
      setSearchData(search);
      setAcceptedRequest(search);
    } else if (activeTab === 2) {
      search = rejectedRequest?.filter((user) => {
        const userName = user?.user?.name?.toLowerCase() || "";
        const requestedUserName =
          user?.requestedUser?.name?.toLowerCase() || "";

        return userName.includes(query) || requestedUserName.includes(query);
      });
      setRejectedRequest(search);
      setSearchData(search);
    }
  };

  const toggleSearchBar = () => {
    setShowAdvanceSearch((prev) => !prev);
    setSearchQuery("");
    setSearchData([]);
    if (showAdvanceSearch) {
      setData(requests);
    }
  };
  const filterRequests = (value) => {
    const today = new Date();
    setFilter(value);

    let sortedData;
    let data = [];

    if (searchData.length > 0) {
      data = searchData;
    } else if (searchQuery && searchData.length === 0) {
      data = [];
    } else if (requests.length > 0) {
      data = requests;
    } else {
      data = requests;
    }

    if (value === "all") {
      setData(data);
      return;
    } else {
      const sortData = data?.filter((user) => {
        if (!user || !user.sentAt) return false;

        const requestDate = new Date(user.sentAt);
        const diffTime = today.getTime() - requestDate.getTime();
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if (value === "today")
          return requestDate.toDateString() === today.toDateString();
        if (value === "week") return diffDays < 7 && diffDays >= 0;
        if (value === "month")
          return (
            requestDate.getMonth() === today.getMonth() &&
            requestDate.getFullYear() === today.getFullYear()
          );
        if (value === "year")
          return requestDate.getFullYear() === today.getFullYear();

        return false;
      });

      sortedData = sortData.sort(
        (a, b) => new Date(b.sentAt) - new Date(a.sentAt),
      );
    }
    setData(sortedData);
    console.log("sortedData",sortedData);
    
  };

  const setData = (data) => {
    const pending = [];
    const accepted = [];
    const rejected = [];

    data?.forEach((request) => {
      if (request?.requestStatus === "Pending") {
        pending.push(request);
      } else if (request?.requestStatus === "Accepted") {
        accepted.push(request);
      } else if (request?.requestStatus === "Rejected") {
        rejected.push(request);
      }
    });

    // Update state with categorized data
    setUserRequestData(pending);
    setAcceptedRequest(accepted);
    setRejectedRequest(rejected);
  };

  const tabList = [
    { name: "User Requests", count: userRequestData.length },
    { name: "Accepted Requests", count: acceptedRequest.length },
    { name: "Rejected Requests", count: rejectedRequest.length },
  ];
  return (
    <div className="font-jost relative ml-4 h-auto rounded-lg p-2 ">
      <div className="flex w-full items-center justify-center md:justify-end text-xs text-gray-400">Note: The details are sorted by the date the request was sent.</div>
      <div className="flex w-full flex-col justify-between border-b border-gray-300  md:flex-row">
        <ul className="flex flex-col justify-center md:flex-row">
          {tabList.map((list, index) => (
            <li
              key={index}
              className={`mt-3 cursor-pointer px-4 py-2 text-center text-textGreen transition-all ${showAdvanceSearch ? "text-[13px] xl:text-[16px]" : "sm:text-xs md:text-base"} 
                    ${
                      activeTab === index
                        ? "border-b-2 border-textYellow text-textYellow "
                        : "hover:bg-gray-100 hover:text-textYellow"
                    }`}
              onClick={() => handleTabClick(index)}
            >
              {list.name}({list.count})
            </li>
          ))}
        </ul>
        <div className="flex flex-wrap items-center justify-center">
          {/* Search and Sort  */}
          <div className="flex flex-row items-center gap-2 ">
            {/* Search Icon & Advanced Search */}
            <div className="flex items-center rounded-lg ">
              {!showAdvanceSearch ? (
                <IoIosSearch
                  className="cursor-pointer text-xl text-gray-500 sm:text-2xl"
                  onClick={toggleSearchBar}
                />
              ) : (
                <div className="flex items-center gap-2">
                  <AdvanceSearch onSearchValue={handleAdvanceSearch} />
                  <ImCancelCircle
                    className="cursor-pointer text-lg text-gray-500 sm:text-xl"
                    onClick={toggleSearchBar}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center flex-col">
              <p className="text-xs">sort by:</p>
              <select
                className="rounded-md border px-1 py-1 text-xs"
                value={filter}
                onChange={(e) => filterRequests(e.target.value)}
              >
                <option value="all">All</option>
                <option value="today">Today</option>
                <option value="week">This Week</option>
                <option value="month">This Month</option>
                <option value="year">This Year</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {activeTab === 0 && (
        <>
          {loading ? (
            <div className="flex min-h-screen flex-col items-center justify-center">
              <Spinner />
            </div>
          ) : userRequestData.length > 0 || searchData.length > 0 ? (
            <div className="flex flex-wrap gap-4 p-2">
              {userRequestData?.map((item, index) => (
                <RequestCards
                  key={index}
                  request={item}
                  activeTab={activeTab}
                  index={index}
                  adminId={adminId}
                  setUserRequestData={setUserRequestData}
                  setAcceptedRequest={setAcceptedRequest}
                  setRejectedRequest={setRejectedRequest}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center p-2">
              <h4 className="text-black">No User Request Available.</h4>
            </div>
          )}
        </>
      )}
      {activeTab === 1 && (
        <>
          {loading ? (
            <div className="flex min-h-screen flex-col items-center justify-center">
              <Spinner />
            </div>
          ) : acceptedRequest.length > 0 ? (
            <div className="flex flex-wrap gap-4 p-2">
              {acceptedRequest.map((item, index) => (
                <RequestCards
                  key={index}
                  request={item}
                  activeTab={activeTab}
                  index={index}
                  adminId={adminId}
                  setUserRequestData={setUserRequestData}
                  setAcceptedRequest={setAcceptedRequest}
                  setRejectedRequest={setRejectedRequest}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center p-2">
              <h4 className="text-black">No Accpted Request Available.</h4>
            </div>
          )}
        </>
      )}
      {activeTab === 2 && (
        <>
          {loading ? (
            <div className="flex min-h-screen flex-col items-center justify-center">
              <Spinner />
            </div>
          ) : rejectedRequest.length > 0 ? (
            <div className="flex flex-wrap gap-4 p-2">
              {rejectedRequest.map((item, index) => (
                <RequestCards
                  key={index}
                  request={item}
                  activeTab={activeTab}
                  index={index}
                  adminId={adminId}
                  setUserRequestData={setUserRequestData}
                  setAcceptedRequest={setAcceptedRequest}
                  setRejectedRequest={setRejectedRequest}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center p-2">
              <h4 className="text-black">No Rejected Request Available.</h4>
            </div>
          )}
        </>
      )}
      <MessageBar />
    </div>
  );
};

export default UserRequest;
