import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";
import Strings from "../../utils/Strings";
import {
  config,
  DOBASEURL,
  errorNotify,
  getValueFromLocalStorage,
  localStorageKey,
  successNotify,
} from "../../utils/helper";
import { authorizedPost } from "../../Config/networkWithToken";
import configuration from "../../Config/configuration";
import Spinner from "../../Components/Spinner/Spinner";
import Images from "../../utils/images";

const ProfileList = ({ userData, role, canSendRequest }) => {
  const [loading, setLoading] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const userId = getValueFromLocalStorage(localStorageKey.USERDATA, "_id");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = Number(searchParams.get("page")) || 1;

  const handleSendRequest = async () => {
    setLoading(true);
    try {
      const data = {
        userId: userData._id,
        requestedUserId: userId,
      };

      const res = await authorizedPost(
        configuration.apis.sendRequest,
        data,
        config,
      );
      const response = res?.data;

      if (response && response.status) {
        successNotify(response.message);
        setButtonStatus(true);
      } else {
        errorNotify(response?.message || "Request failed unexpectedly.");
      }
    } catch (error) {
      console.log(error);
      errorNotify("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative flex flex-col gap-2 rounded-lg border border-gray-200 bg-bgWhite pb-4 pt-6 shadow-sm md:h-[280px] md:flex-row xl:h-[220px] ">
      <div className="flex items-center justify-center md:h-[200px] md:w-[25%] xl:h-[120px] xl:w-[15%]">
        <img
          src={
            userData?.photos?.[0]
              ? DOBASEURL + userData.photos[0]
              : userData?.images?.[0]
                ? DOBASEURL + userData.images[0]
                : userData?.gender === "male"
                  ? Images.MEN
                  : Images.WOMEN
          }
          alt=""
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
          className={`mt-10 h-full w-full rounded-lg object-cover shadow-md ${role === "admin" || userData?.requestStatus === "Accepted" ? "" : "blur"}`}
        />
      </div>

      <div className="flex w-full flex-col items-center justify-center gap-2 p-2 md:flex-col xl:flex-row">
  {/* Profile Content */}
  <div className="w-full text-black xl:w-[80%]">
    {role !== "user" && (
      <h2 className="font-jost text-lg font-semibold">{userData.name}</h2>
    )}

    <div className="font-jost flex flex-col justify-between gap-1 md:flex-row">
      {/* Left Info */}
      <div className="flex w-full flex-col md:w-[60%]">
        <div className="flex w-full flex-col justify-between gap-1 md:flex-row">
          {/* Left Column */}
          <div className="flex w-full flex-col gap-1 md:w-[50%]">
            <div>
              {Strings.DOB}:{" "}
              <span className="text-textGreen">
                {new Date(userData?.dob).toLocaleDateString("en-CA") || "NA"}
              </span>
            </div>
            <div>
              {Strings.BIRTH_TIME}:{" "}
              <span className="text-textGreen">
                {userData?.birthTime || "NA"}
              </span>
            </div>
            <div>
              {Strings.BIRTH_CITY}:{" "}
              <span className="text-textGreen">
                {userData?.birthCity || "NA"}
              </span>
            </div>
            <div className="font-jost">
              {Strings.OCCUPATIONS}:{" "}
              <span className="text-textGreen">
                {userData?.occupations?.length
                  ? userData?.occupations?.[0]?.slice(0, 15) +
                    (userData?.occupations.join(", ").length > 15 ? "..." : "")
                  : "NA"}
              </span>
            </div>
          </div>

          {/* Right Column */}
          <div className="flex w-full flex-col gap-1 md:w-[50%]">
            <div>
              {Strings.HEIGHT}:{" "}
              <span className="text-textGreen">{userData?.height || "NA"}</span>
            </div>
            <div>
              {Strings.MOTHERTONGUE}:{" "}
              <span className="text-textGreen">
                {userData?.motherTongue || "NA"}
              </span>
            </div>
            <div>
              {Strings.EDUCATION}:{" "}
              <span className="text-textGreen">
                {userData?.education?.length
                  ? userData?.education?.[0]?.slice(0, 15) +
                    (userData?.education.join(", ").length > 15 ? "..." : "")
                  : "NA"}
              </span>
            </div>
            <div>
              {Strings.Marital}:{" "}
              <span className="text-textGreen">
                {userData?.maritalStatus || "NA"}
              </span>
            </div>
            {userData?.maritalStatus !== "never married" && (
              <div>
                {Strings.Children}:{" "}
                <span className="text-textGreen">
                  {userData?.childrenCount || "0"}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Additional Info */}
      <div className="flex w-full flex-col gap-1 md:w-[40%]">
        <div>
          {Strings.ANNUAL_INCOME}:{" "}
          <span className="text-textGreen">
            {userData?.annualIncome || "NA"}
          </span>
        </div>
        <div>
          {Strings.SPIRITUAL_STATUS}:{" "}
          <span className="text-textGreen">
            {userData?.spiritualStatus || "NA"}
          </span>
        </div>
        <div>
          {Strings.CASTE}:{" "}
          <span className="text-textGreen">{userData?.caste || "NA"}</span>
        </div>
        {role === "admin" && (
          <div>
            Registration Date:{" "}
            <span className="text-textGreen">
              {new Date(userData?.registerDate).toLocaleDateString("en-CA") ||
                "NA"}
            </span>
          </div>
        )}
      </div>
    </div>
  </div>

  {/* Button Section */}
<div className="w-full  xl:w-auto xl:ml-4 flex flex-col gap-2 items-center">
  {role === "admin" && (
    <Link
      to={`/matrimony/${currentPage}/viewProfile/${userData?._id || userData?.userId}`}
      className="w-full"
    >
      <button className="font-jost w-full rounded-lg bg-textYellow px-2 py-1 text-xs text-white md:w-auto md:px-4 md:py-2 lg:text-sm">
        {Strings.VIEW_PROFILE}
      </button>
    </Link>
  )}

{role === "user" && canSendRequest && (
  <div className="w-full md:flex md:justify-start">
    <>
      {buttonStatus ? (
        <button className="w-full rounded-lg bg-textYellow px-2 py-1 text-sm text-white md:w-auto md:px-6 md:py-2">
          Pending
        </button>
      ) : (
        <button
          className="w-full rounded-lg bg-textYellow px-2 py-1 text-sm text-white md:w-auto md:px-6 md:py-2"
          onClick={handleSendRequest}
          disabled={loading}
        >
          {loading ? (
            <Spinner className="mx-auto" />
          ) : (
            "Send"
          )}
        </button>
      )}
    </>
  </div>
)}

</div>
</div>




    </div>
  );
};

export default ProfileList;



