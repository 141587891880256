import React, { useState, useEffect } from "react";
import { BiFemale, BiMale } from "react-icons/bi";
import { FaRegPenToSquare } from "react-icons/fa6";
import { GiLovers } from "react-icons/gi";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const months = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
];

const weeks = ["Week 1", "Week 2", "Week 3", "Week 4"];

const DashBoard = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonthIndex = currentDate.getMonth();
  const currentMonth = months[currentMonthIndex];

  const [filteredStats, setFilteredStats] = useState([]);
  const [sortBy, setSortBy] = useState("thisYear");

  const [startDate, setStartDate] = useState({
    month: "Jan",
    year: currentYear - 1,
  });
  const [endDate, setEndDate] = useState({
    month: currentMonth,
    year: currentYear,
  });

  const monthlyStats = [];
  for (let y = currentYear - 5; y <= currentYear; y++) {
    for (let m = 0; m < 12; m++) {
      if (y === currentYear && m > currentMonthIndex) break;
      monthlyStats.push({
        month: months[m],
        year: y,
        Married: Math.floor(Math.random() * 100),
        Active: Math.floor(Math.random() * 200),
        Registered: Math.floor(Math.random() * 300),
      });
    }
  }

  const weeklyStats = weeks.map((week) => ({
    month: week,
    year: currentYear,
    Married: Math.floor(Math.random() * 100),
    Active: Math.floor(Math.random() * 200),
    Registered: Math.floor(Math.random() * 300),
  }));

  useEffect(() => {
    let filtered = [];

    if (sortBy === "custom") {
      const startIndex = monthlyStats.findIndex(
        (item) => item.month === startDate.month && item.year === startDate.year
      );
      const endIndex = monthlyStats.findIndex(
        (item) => item.month === endDate.month && item.year === endDate.year
      );

      if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
        const range = monthlyStats.slice(startIndex, endIndex + 1);

        if (
          startDate.year === endDate.year &&
          startDate.month === endDate.month
        ) {
          // Same month and same year: show weekly data
          filtered = weeklyStats;
        } else if (startDate.year === endDate.year) {
          // Same year, show monthly data
          filtered = range;
        } else {
          // Different years - group by year
          const groupedByYear = {};
          range.forEach((item) => {
            const year = item.year;
            if (!groupedByYear[year]) {
              groupedByYear[year] = {
                year,
                Married: 0,
                Active: 0,
                Registered: 0,
                count: 0,
              };
            }
            groupedByYear[year].Married += item.Married;
            groupedByYear[year].Active += item.Active;
            groupedByYear[year].Registered += item.Registered;
            groupedByYear[year].count += 1;
          });

          filtered = Object.values(groupedByYear).map((yearData) => ({
            month: String(yearData.year),
            Married: Math.round(yearData.Married / yearData.count),
            Active: Math.round(yearData.Active / yearData.count),
            Registered: Math.round(yearData.Registered / yearData.count),
          }));
        }
      }
    } else if (sortBy === "thisYear") {
      filtered = monthlyStats.filter((item) => item.year === currentYear);
    } else if (sortBy === "previousYear") {
      filtered = monthlyStats.filter((item) => item.year === currentYear - 1);
    } else if (sortBy === "thisMonth") {
      filtered = weeklyStats;
    }

    setFilteredStats(filtered);
  }, [sortBy, startDate, endDate]);

  const cards = [
    {
      icon: <FaRegPenToSquare />,
      head: "Total Registrations",
      users: "3,000",
      bgColor: "bg-gradient-to-r from-yellow-400 to-yellow-600",
    },
    {
      icon: <GiLovers />,
      head: "Active Users",
      users: "2,100",
      bgColor: "bg-gradient-to-r from-green-400 to-green-600",
    },
    {
      icon: <BiMale />,
      head: "Male Users",
      users: "1,600",
      bgColor: "bg-gradient-to-r from-cyan-500 to-cyan-700",
    },
    {
      icon: <BiFemale />,
      head: "Female Users",
      users: "1,400",
      bgColor: "bg-gradient-to-r from-pink-400 to-pink-600",
    },
  ];

  const summaryData = [
  { label: "Married Users", count: 850 },
  { label: "Registered Users", count: 3000 },
  { label: "Active Users", count: 2100 },
  { label: "Male Users", count: 1600 },
  { label: "Female Users", count: 1400 },
  { label: "Active Male Users", count: 1200 },
  { label: "Active Female Users", count: 900 },
];


  return (
    <div className="mx-auto w-[97%] pb-12">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {cards.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col items-center justify-center rounded-xl p-4 text-white ${item.bgColor}`}
          >
            <div className="mb-2 text-3xl">{item.icon}</div>
            <h3 className="text-lg font-semibold">{item.head}</h3>
            <p className="text-2xl font-bold">{item.users}</p>
          </div>
        ))}
      </div>

      <div className="mt-12 rounded-2xl bg-white p-6">
        <div className="mb-6 flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
          <div>
            <h2 className="text-2xl font-bold text-gray-800">
              User Growth & Engagement
            </h2>
            <p className="text-sm text-gray-500">Overview of user activity</p>
          </div>

          <div className="flex flex-col gap-2 sm:flex-row sm:items-center">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className="rounded-lg border px-4 py-2 text-sm shadow-sm outline-none"
            >
              <option value="thisMonth">This Month ({currentMonth})</option>
              <option value="thisYear">This Year ({currentYear})</option>
              <option value="previousYear">Previous Year ({currentYear - 1})</option>
              <option value="custom">Custom Range</option>
            </select>

            {sortBy === "custom" && (
              <div className="flex flex-wrap items-center gap-2">
                <div className="flex gap-1">
                  <select
                    value={startDate.month}
                    onChange={(e) =>
                      setStartDate({ ...startDate, month: e.target.value })
                    }
                    className="rounded border px-2 py-1 text-sm"
                  >
                    {months.map((m) => (
                      <option key={m}>{m}</option>
                    ))}
                  </select>
                  <select
                    value={startDate.year}
                    onChange={(e) =>
                      setStartDate({ ...startDate, year: parseInt(e.target.value) })
                    }
                    className="rounded border px-2 py-1 text-sm"
                  >
                    {Array.from({ length: 6 }).map((_, idx) => {
                      const y = currentYear - 5 + idx;
                      return <option key={y}>{y}</option>;
                    })}
                  </select>
                </div>

                <div className="flex gap-1">
                  <select
                    value={endDate.month}
                    onChange={(e) =>
                      setEndDate({ ...endDate, month: e.target.value })
                    }
                    className="rounded border px-2 py-1 text-sm"
                  >
                    {months.map((m) => (
                      <option key={m}>{m}</option>
                    ))}
                  </select>
                  <select
                    value={endDate.year}
                    onChange={(e) =>
                      setEndDate({ ...endDate, year: parseInt(e.target.value) })
                    }
                    className="rounded border px-2 py-1 text-sm"
                  >
                    {Array.from({ length: 6 }).map((_, idx) => {
                      const y = currentYear - 5 + idx;
                      return y <= currentYear && <option key={y}>{y}</option>;
                    })}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>

        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            data={filteredStats}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorRegistered" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#f59e0b" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#f59e0b" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorActive" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#22c55e" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#22c55e" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorMarried" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#a855f7" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#a855f7" stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
            <XAxis dataKey="month" tick={{ fill: "#6b7280" }} />
            <YAxis tick={{ fill: "#6b7280" }} />

            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="rounded-xl border bg-white p-4 shadow-md">
                      <p className="font-semibold text-gray-700">{label}</p>
                      {payload.map((entry, index) => (
                        <p
                          key={index}
                          className="text-sm"
                          style={{ color: entry.color }}
                        >
                          {entry.name}: {entry.value}
                        </p>
                      ))}
                    </div>
                  );
                }
                return null;
              }}
            />

            <Legend
              verticalAlign="bottom"
              align="center"
              formatter={(value) => (
                <span className="text-sm text-gray-600">{value}</span>
              )}
            />

            <Area
              type="monotone"
              dataKey="Registered"
              stroke="#f59e0b"
              fillOpacity={1}
              fill="url(#colorRegistered)"
              dot={{ r: 4, stroke: "#f59e0b", strokeWidth: 2, fill: "white" }}
            />
            <Area
              type="monotone"
              dataKey="Active"
              stroke="#22c55e"
              fillOpacity={1}
              fill="url(#colorActive)"
              dot={{ r: 4, stroke: "#22c55e", strokeWidth: 2, fill: "white" }}
            />
            <Area
              type="monotone"
              dataKey="Married"
              stroke="#a855f7"
              fillOpacity={1}
              fill="url(#colorMarried)"
              dot={{ r: 4, stroke: "#a855f7", strokeWidth: 2, fill: "white" }}
            />
          </AreaChart>
        </ResponsiveContainer>
        <div className="mt-10 rounded-2xl bg-white p-6 shadow">
  <h3 className="mb-4 text-xl font-semibold text-gray-800">User Summary</h3>
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200 text-sm">
      <thead className="bg-gray-100 text-gray-600">
        <tr>
          <th className="px-4 py-2 text-left">Category</th>
          <th className="px-4 py-2 text-right">Count</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {summaryData.map((item, index) => (
          <tr key={index}>
            <td className="px-4 py-2 text-gray-700">{item.label}</td>
            <td className="px-4 py-2 text-right font-semibold text-gray-800">
              {item.count}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div className="mt-6 text-right">
    <button
      onClick={() => console.log("Download triggered")}
      className="rounded-lg bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
    >
      Download Report
    </button>
  </div>
</div>

      </div>
    </div>
  );
};

export default DashBoard;
