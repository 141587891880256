const Images = {
  MEN: require("../Assets/men.jpg"),
  WOMEN: require("../Assets/profile.jpg"),
  DEFAULT_USER: require("../Assets/user.jpg"),

  LINKEDIN: require("../Assets/Linkedin.png"),
  TWITTER: require("../Assets/Twitter.png"),
  // FACEBOOK: require("../Assets/Facebook.png"),
  YOUTUBE: require("../Assets/Youtube.png"),
  ABOUTIMG: require("../Assets/aboutImg.jpg"),
  RING: require("../Assets/ring.png"),
  PRIZE: require("../Assets/prize.png"),
  TRUST: require("../Assets/trust.png"),
  OFFICE: require("../Assets/office.jpeg"),
  TELEPHONE: require("../Assets/telephone.png"),
  MARRIAGEHEART: require("../Assets/marriage2.png"),
  MARRIAGELOGO: require("../Assets/marriageLogo.png"),
  LoginBg: require("../Assets/bg.png"),
  TESTIMONALS1: require("../Assets/Testimonials1.jpeg"),
  FAQS: require("../Assets/faqs.svg"),
  LOGO: require("../Assets/VaishnavaMarriageLogo.jpeg"),
  VMBLOGO: require("../Assets/VMB Logo.PNG"),
};

export default Images;
